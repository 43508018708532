export default {
  data() {
    return {
      year: [],
      month: [],
      day: [],
    }
  },
  methods: {
    checkDates(year1, year2, month1, month2) {
      if (year1 === '' || year2 === '') return 'OK' //무관
      if (Number(year1) > Number(year2)) {
        return 'YEAR' //연도 에러
      }
      if (month1 === '' || month2 === '') return 'OK'

      const startDate = new Date(year1 + '-' + month1 + '-01')
      const endDate = new Date(year2 + '-' + month2 + '-01')

      const result = endDate.getTime() - startDate.getTime()
      if (result >= 0) return 'OK'
      else {
        return 'MONTH'
      }
    },
    checkStartDate(year1, month1) {
      if (year1 === '' || month1 === '') return true

      const startDate = new Date(year1 + '-' + month1 + '-01')
      let today = new Date()
      today = new Date(
        today.getFullYear() + '-' + (today.getMonth() + 1) + '-01',
      )
      console
      const result = today.getTime() - startDate.getTime()
      if (result > 0) return true
      else return false
    },
    validateInput(data) {
      let ok = true
      for (let value of Object.values(data)) {
        if (value === '' || value === null) {
          ok = false
          break
        }
      }
      return ok
    },
    checkValues(list) {
      let isBreak = false

      for (let idx = 0; idx < list.length; idx++) {
        const item = list[idx]
        for (let key of Object.keys(item)) {
          if (
            item[key] === '' ||
            item[key] === null ||
            item[key] === undefined
          ) {
            this.$toasted.error('입력/선택 하지 않은 값을 확인 해주세요.')
            if (
              this.$refs[`${key}_${idx}`] &&
              this.$refs[`${key}_${idx}`][0].focus
            ) {
              this.$refs[`${key}_${idx}`][0].focus()
            }
            isBreak = true
            break
          }
        }
        if (isBreak) break
      }

      if (isBreak) return false
      return true
    },

    //마지막 남은 항목일 경우 폼 데이터 비우기
    deleteItemOrClearForm(list, idx) {
      // if (list.length === 1) {
      //   const item = list[0]
      //   for (let key of Object.keys(item)) {
      //     this.$set(item, key, '') //for reactive
      //     //item[key] = ''
      //   }
      // } else {
      //   list.splice(idx, 1)
      // }
      // return
      list.splice(idx, 1)
    },

    getStartEnd(startDate, endDate) {
      const start = startDate
      const end = endDate

      let resultArray = []

      for (let i = start; i <= end; i++) {
        resultArray.push(i)
      }

      return resultArray
    },

    // 년, 월, 일 세팅
    initDateList() {
      const startDay = 1
      const endDay = 31
      this.day = this.getStartEnd(startDay, endDay)
      this.month = this.getStartEnd(1, 12)
      const startYear = 1950
      const endYear = new Date().getFullYear() //금년도 세팅
      this.year = this.getStartEnd(startYear, endYear).sort((a, b) => b - a)
    },
  },
}
