import { render, staticRenderFns } from "./EducationApply.vue?vue&type=template&id=259ae3b5&scoped=true&"
import script from "./EducationApply.vue?vue&type=script&lang=js&"
export * from "./EducationApply.vue?vue&type=script&lang=js&"
import style0 from "./EducationApply.vue?vue&type=style&index=0&id=259ae3b5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "259ae3b5",
  null
  
)

export default component.exports