<template>
  <div>
    <main class="sub __contact __guide">
      <div class="inner __faq">
        <div class="tit req-type">
          <h1>교육신청</h1>
          <p class="stit">호구153만의 체계적인 교육을 받으실 수 있습니다.</p>
        </div>
        <div class="sub-cont">
          <div class="guide-tapmenu-wrap">
            <ul style="cursor: pointer">
              <li>
                <router-link
                  :class="{ on: routeName === 'eduApply' }"
                  to="/eduApply"
                >
                  교육신청
                </router-link>
              </li>

              <li>
                <router-link
                  :class="{ on: routeName === 'videoWatch' }"
                  to="/video-watch"
                >
                  교육 동영상
                </router-link>
              </li>
              <li>
                <router-link
                  :class="{ on: routeName === 'resumeEdu' }"
                  to="/resume/edu"
                >
                  교육 이수 내역
                </router-link>
              </li>
            </ul>
          </div>
          <ul class="EduCourse-list" id="EduCourseList">
            <li v-for="(EduCourse, idx) in EduCourseList" :key="`edc_${idx}`">
              <div
                class="EduTitle"
                :class="{ on: activeNum === EduCourse.hgEduId }"
                @click="openAnswer(EduCourse.hgEduId)"
              >
                <p class="Title">
                  {{ EduCourse.className }} - {{ EduCourse.subjectName }}
                </p>
                <div class="status-con">
                  <span class="EduDt">
                    {{ EduCourse.strtDt }}({{ getDay(EduCourse.strtDt) }}) -
                    {{ EduCourse.endDt }}({{ getDay(EduCourse.endDt) }})
                  </span>
                  <span
                    class="btn-status btn-poss"
                    v-if="EduCourse.useYn === '0'"
                    >신청가능</span
                  >
                  <span
                    class="btn-status btn-comp"
                    v-if="EduCourse.useYn === '1'"
                    >신청완료</span
                  >
                </div>
              </div>

              <div
                class="EduCont a"
                :class="{ on: activeNum === EduCourse.hgEduId }"
              >
                <div class="input-form-wrap EduList">
                  <div class="input-form">
                    <div class="t-head">{{ EDUCATION_LIST[0] }}</div>
                    <div class="t-doc">
                      <input
                        v-model="EduCourse.className"
                        type="text"
                        class="text"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="input-form">
                    <div class="t-head">{{ EDUCATION_LIST[1] }}</div>
                    <div class="t-doc">
                      <input
                        v-model="EduCourse.subjectName"
                        type="text"
                        class="text"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="input-form">
                    <div class="t-head">{{ EDUCATION_LIST[2] }}</div>
                    <div class="t-doc">
                      <input
                        :value="EduCourse.lectureTime + '분'"
                        type="text"
                        class="text"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="input-form">
                    <div class="t-head">{{ EDUCATION_LIST[3] }}</div>
                    <div class="t-doc">
                      <input
                        v-model="EduCourse.lectureType"
                        type="text"
                        class="text"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="input-form">
                    <div class="t-head">{{ EDUCATION_LIST[4] }}</div>
                    <div class="t-doc">
                      <input
                        v-model="EduCourse.classLevel"
                        type="text"
                        class="text"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="input-form">
                    <div class="t-head">{{ EDUCATION_LIST[5] }}</div>
                    <div class="t-doc">
                      <input
                        v-model="EduCourse.classroom"
                        type="text"
                        class="text"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="input-form">
                    <div class="t-head">{{ EDUCATION_LIST[6] }}</div>
                    <div class="t-doc">
                      <input
                        v-model="EduCourse.strtDt"
                        type="text"
                        class="text"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="input-form">
                    <div class="t-head">{{ EDUCATION_LIST[7] }}</div>
                    <div class="t-doc">
                      <input
                        v-model="EduCourse.endDt"
                        type="text"
                        class="text"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="input-form">
                    <div class="t-head">{{ EDUCATION_LIST[8] }}</div>
                    <div class="t-doc">
                      <input
                        :value="
                          formatDate(EduCourse.classStartTime) +
                          '-' +
                          formatDate(EduCourse.classEndTime)
                        "
                        type="text"
                        class="text"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="myp-btn-wrap">
                  <button
                    class="btn-save01"
                    type="button"
                    :class="{ on: EduCourse.useYn === '0' }"
                    @click="apply(EduCourse.hgEduId, idx)"
                  >
                    신청하기
                  </button>
                  <button
                    class="btn-cancel"
                    type="button"
                    :class="{ on: EduCourse.useYn === '1' }"
                    @click="cancel(EduCourse.hgEduId, idx)"
                  >
                    신청취소
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import resumeMixin from 'Mixins/resume'
import {
  getHogoo153EduCourseList,
  getHogoo153EduApply,
  deleteHoguEduInfo,
} from 'Api/modules'
export default {
  data() {
    return {
      EDUCATION_LIST: [
        '교육과정명',
        '과목명',
        '교육분량(분)',
        '교육형태',
        '교육수준',
        '교육장소',
        '교육시작일자',
        '교육종료일자',
        '교육시간',
      ],
      EduCourseList: [],
      activeNum: null,
      isApplyBtn: false,
    }
  },
  computed: {
    EduStatus() {
      for (let i = 0; i < this.EduCourseList.length; i++) {
        if (this.EduCourseList[i].useYn === '0') {
          return '신청가능'
        } else if (this.EduCourseList[i].useYn === '1') {
          return '신청완료'
        }
      }
      return undefined
    },
  },
  mixins: [resumeMixin],
  methods: {
    getDay(date) {
      const week = ['일', '월', '화', '수', '목', '금', '토']
      return week[new Date(date).getDay()]
    },
    formatDate(date) {
      return date.substring(0, 2) + ':' + date.substring(2)
    },
    async apply(hgEduId, idx) {
      var _self = this
      try {
        await getHogoo153EduApply({ hgEduId: this.EduCourseList[idx].hgEduId })
        this.$toasted.show('신청이 완료되었습니다.')
        setTimeout(function () {
          _self.$router.go()
        }, 500)

        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async cancel(hgEduId, idx) {
      var _self = this
      try {
        await deleteHoguEduInfo({ hgEduId: this.EduCourseList[idx].hgEduId })
        this.$toasted.error('취소되었습니다.')
        setTimeout(function () {
          _self.$router.go()
        }, 500)

        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    openAnswer(EduId) {
      if (this.activeNum === EduId) this.activeNum = null
      else {
        this.activeNum = EduId
      }
    },
  },
  async created() {
    const res = await getHogoo153EduCourseList()
    this.EduCourseList = res
  },
}
</script>
<style scoped>
#edduApply-con {
  margin-top: 30px;
  width: 100%;
  height: 500px;
  border: 1px solid #dedede;
  border-radius: 8px;
}
.inner {
  width: 100%;
  border: none;
}
table {
  width: 100%;
}
.myp-btn-wrap .btn-save01,
.myp-btn-wrap .btn-cancel {
  display: none;
}
.myp-btn-wrap .btn-save01 .on,
.myp-btn-wrap .btn-cancel .on {
  display: block;
}
</style>
